import styled from 'styled-components'
import { Layout, Button, Form } from 'antd'

import { colors } from 'Common/constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
padding: 1rem;
min-height: calc(100vh - 88px);
@media (min-width: 672px) {
  width: ${props=>props.width};
  margin: 0 auto;
  padding: 1rem;
}
display: grid;
gap: 16px;
grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
  width: 100%
`
export const PageTitle = styled.h1`
  color: ${colors.main};
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`
export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  & label:after {
    content: unset;
  }
`

export const SubmitButton = styled(Form.Item)`
  width: 100%
  & .ant-form-item-control {
    line-height: unset;
  }
`