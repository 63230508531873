import {
  GET_STORE,
  GET_STORES, 
  SET_STORE,
  GET_CLIENT_IP 
} from '../action-types'

export const initialState = { clientIp: null, store: null, stores: [] }
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STORE:
      return { ...state, store: payload }    
    case GET_STORES:
      return { ...state, stores: payload }
    case SET_STORE:
      return { ...state, store: payload }     
    case GET_CLIENT_IP:
      return { ...state, ...payload }
    
    default:
      return state
  }
}
