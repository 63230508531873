import React from 'react'
import { Link } from 'react-router-dom'
import MainLayout from 'Hoc/MainLayout'
const Page404 = props => (
  <MainLayout>
    <h1>La pagina che stavi cercando non esiste</h1>
    <Link to="/">Torna al sito</Link>
  </MainLayout>
)

export default Page404
