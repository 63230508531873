import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Page404 from 'Containers/404'


class App extends Component {
  render() {
    
    return (
     
            <Switch>
              <Route path={'/'} component={(props) => { window.location = `https://loyalty.lmit.app/`; return null;} }/>             
              <Route path='/external/:id' component={(props) => { window.location = `https://loyalty.lmit.app/`; return null;} }/>
              <Route component={Page404} />
            </Switch>
          
    )
  }
}

export default App
