import React from 'react'
import { Link } from 'react-router-dom'

import { StyledHeader, HeaderBar, AppLogo } from './styled'

const Header = props => {
  
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        <Link to={'/'}>
          <AppLogo src="/assets/img/logo.png" />
        </Link>       
      </StyledHeader>
    </>
  )
}

Header.propTypes = {
  
}
export default Header
