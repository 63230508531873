import React, { Component } from 'react'

import Header from 'Components/Header'
import Footer from 'Components/Footer'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class MainLayout extends Component {
  
  render() {
    
    return (
      <WhiteLayout>
        <Header />
        <PaddedContent>{this.props.children}</PaddedContent>
        <Footer />
      </WhiteLayout>
    )
  }
}
export default MainLayout
